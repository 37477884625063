<template>
  <two-factor-tab-item-enable-content
    :loading="loading"
    :error="error"
    :handle-action="handleEnable"
  />
</template>

<script>
import TwoFactorTabItemEnableContent from './TwoFactorTabItemEnableContent.vue';

export default {
  name: 'TwoFactorTabItemEnable',

  components: {
    TwoFactorTabItemEnableContent,
  },

  data() {
    return {
      loading: {
        qr: false,
        enable: false,
      },
      error: '',
    };
  },

  created() {
    this.loading.qr = true;

    this.api.put('/two-factory/enable')
      .then(({ data }) => {
        this.$store.dispatch('settings/setTwoFactor', {
          qr: data['qr-code'],
          secretKey: data.secretKey,
        });
      }).finally(() => {
        this.loading.qr = false;
      });
  },

  destroyed() {
    this.$store.dispatch('settings/setTwoFactor', {
      qr: '',
      secretKey: '',
    });
  },

  methods: {
    async handleEnable(code) {
      try {
        this.loading.enable = true;

        const { status, data } = await this.api.post('/two-factory/confirm', { code });

        if (status === 200) {
          this.$store.dispatch('main/alert', { color: 'success', message: 'Успешно' });
          await this.$store.dispatch('settings/setTwoFactor', {
            backupCodes: data,
          });
          this.$store.dispatch('settings/openDialog', 'backupCodes');
          this.$store.dispatch('settings/updateProfileLocally', {
            twoFactorEnableDisable: true,
          });
        }
      } finally {
        this.loading.enable = false;
      }
    },
  },
};
</script>
