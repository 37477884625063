<template>
  <v-row>
    <!-- 1 ШАГ -->
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-card outlined>
        <v-card-text>
          <div class="card-text-title">
            1 шаг
          </div>

          <div class="card-text-subtitle mb-2">
            Скачайте приложение для аутентификации
          </div>

          <div class="mb-1">
            Загрузите одно из приложений на свой телефон или планшет:
          </div>

          <ul
            v-for="(app, index) in apps"
            :key="index"
            class="mb-1"
          >
            <li>
              {{ app.name }}
              (<a
                :href="app.androidLink"
                target="_blank"
              >Android</a>, <a
                :href="app.iosLink"
                target="_blank"
              >iOS</a>)
            </li>
          </ul>

          <div>
            Откройте приложение аутентификации, нажмите на значок "+" в правом верхнем углу приложения.
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- 1 ШАГ КОНЕЦ -->

    <!-- 2 ШАГ -->
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-card outlined>
        <v-card-text>
          <div class="card-text-title">
            2 шаг
          </div>

          <v-progress-circular
            v-if="loading.qr"
            indeterminate
            color="primary"
          />

          <template v-if="twoFactor.qr && twoFactor.secretKey">
            <div class="card-text-subtitle mb-2">
              C помощью приложения, которое вы скачали, отсканируйте этот QR-код:
            </div>

            <div
              class="qr-container"
              v-html="twoFactor.qr"
            />

            <div class="mt-2">
              Или введите этот секретный код:
            </div>

            <div>
              {{ twoFactor.secretKey }}
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- 2 ШАГ КОНЕЦ -->

    <!-- 3 ШАГ -->
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-card outlined>
        <v-card-text class="card-text-with-button">
          <div>
            <div class="card-text-title">
              3 шаг
            </div>

            <div class="step-subtitle mb-2">
              Введите код, сгенерированный вашим приложением
            </div>

            <div class="mb-8">
              Введите 6-значный код, сгенерированный вашим приложением для аутентификации.
            </div>
          </div>

          <div>
            <v-text-field
              v-model="code"
              rounded
              dense
              outlined
              class="mb-2"
              label="6-значный код"
              :disabled="loading.enable"
              :hide-details="!error"
              :error-messages="error"
              @keydown.enter="handleAction(code)"
            />

            <v-btn
              :disabled="code.length !== 6"
              color="primary"
              width="100%"
              height="44px"
              rounded
              :loading="loading.enable"
              @click="handleAction(code)"
            >
              Включить
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- 3 ШАГ КОНЕЦ -->
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TwoFactorTabItemEnableContent',

  props: {
    loading: {
      type: Object,
      default: () => ({
        qr: false,
        enable: false,
      }),
    },
    error: {
      type: String,
      default: '',
    },
    handleAction: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      apps: [
        {
          name: 'Google Authenticator',
          androidLink: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
          iosLink: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
        },
        {
          name: 'Duo Mobile',
          androidLink: 'https://play.google.com/store/apps/details?id=com.duosecurity.duomobile&hl=en_US',
          iosLink: 'https://apps.apple.com/us/app/duo-mobile/id422663827',
        },
        {
          name: 'Microsoft Authenticator',
          androidLink: 'https://play.google.com/store/apps/details?id=com.azure.authenticator&referrer=adjust_reftag%3DcKNKUQrsWqbvm',
          iosLink: 'https://apps.apple.com/app/microsoft-authenticator/id983156458',
        },
      ],
      code: '',
    };
  },

  computed: {
    ...mapGetters({
      twoFactor: 'settings/twoFactor',
    }),
  },
};
</script>
