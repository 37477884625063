<template>
  <v-dialog
    scrollable
    :value="dialogs.backupCodes"
    width="600"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title class="headline">
        Сделайте копию ваших 8 резервных кодов
      </v-card-title>

      <v-card-text>
        <div>Если вы потеряете свое устройство аутентификации, для входа в свою учетную запись можно использовать резервный код. Каждый код может быть использован один раз. Храните их в надежном месте</div>

        <v-progress-circular
          v-if="loading"
          color="primary"
          class="mt-4"
          indeterminate
        />

        <div class="d-flex flex-wrap mt-2">
          <v-chip
            v-for="code of twoFactor.backupCodes"
            :key="code"
            outlined
            class="mr-2"
          >
            {{ code }}
          </v-chip>
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          :disabled="loading"
          @click="changeCodes"
        >
          Сбросить коды
        </v-btn>
        <v-btn
          text
          :disabled="loading"
          @click="copyToClipboard(twoFactor.backupCodes)"
        >
          Копировать КОДЫ
        </v-btn>
        <v-btn
          text
          color="grey"
          @click="closeDialog"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import clipboard from '@/mixins/clipboard';

export default {
  name: 'TwoFactorTabItemDialogCodes',

  mixins: [clipboard],

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      dialogs: 'settings/dialogs',
      twoFactor: 'settings/twoFactor',
    }),
  },

  created() {
    if (!this.twoFactor.backupCodes) {
      this.loading = true;
      this.api.get('two-factory/show-codes')
        .then(({ data }) => {
          this.$store.dispatch('settings/setTwoFactor', {
            backupCodes: data,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('settings/closeDialog', 'backupCodes');
    },

    changeCodes() {
      this.loading = true;
      this.$store.dispatch('settings/setTwoFactor', {
        backupCodes: '',
      });

      this.api.put('two-factory/change-codes')
        .then(({ data }) => {
          this.$store.dispatch('main/alert', { color: 'success', message: 'Сбросил' });
          this.$store.dispatch('settings/setTwoFactor', {
            backupCodes: data,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
