<template>
  <v-row>
    <!-- ВАШИ РЕЗЕРВНЫЕ КОДЫ -->
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-card outlined>
        <v-card-text class="card-text-with-button">
          <div>
            <div class="card-text-title">
              Ваши резервные коды
            </div>

            <div class="mb-8">
              Если вы потеряете свое устройство аутентификации, для входа в свою учетную запись можно использовать резервный код. Каждый код может быть использован один раз. Храните их в надежном месте
            </div>
          </div>

          <v-btn
            color="primary"
            width="100%"
            rounded
            @click="$store.dispatch('settings/openDialog', 'backupCodes');"
          >
            Посмотреть коды сброса
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- ВАШИ РЕЗЕРВНЫЕ КОДЫ КОНЕЦ -->

    <!-- НАСТРОЙКИ ДВУХФАКТОРНОЙ АУТЕНТИФИКАЦИИ -->
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-card outlined>
        <v-card-text class="card-text-with-button">
          <div class="card-text-title">
            Настройки двухфакторной аутентификации
          </div>

          <div>
            <v-btn
              color="primary"
              width="100%"
              rounded
              class="mb-3"
              @click="handleApply('changeDevice')"
            >
              Сменить устройство
            </v-btn>

            <v-btn
              color="primary"
              width="100%"
              rounded
              @click="handleApply('turnOff')"
            >
              Выключить
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- НАСТРОЙКИ ДВУХФАКТОРНОЙ АУТЕНТИФИКАЦИИ КОНЕЦ -->
  </v-row>
</template>

<script>
export default {
  name: 'TwoFactorTabItemManage',

  methods: {
    async handleApply(value) {
      await this.$store.dispatch('settings/setTwoFactor', {
        applyType: value,
      });
      this.$store.dispatch('settings/openDialog', 'apply');
    },
  },
};
</script>
