<template>
  <v-dialog
    scrollable
    :value="dialogs.apply"
    width="600"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title class="headline">
        Двухфакторная аутентификация
      </v-card-title>

      <v-card-text>
        <span>Введите 6-значный код аутентификации, сгенерированный приложением authenticator на вашем телефоне. Если вы потеряли доступ к своему устройству аутентификации, введите один из своих резервных кодов.</span>

        <v-text-field
          v-model="code"
          autofocus
          rounded
          dense
          outlined
          hide-details
          :disabled="loading"
          class="mb-2 mt-8"
          label="Код из authenticator или один из резервных кодов"
          @keydown.enter="handleApply"
        />

        <v-btn
          :disabled="code.length < 6"
          color="primary"
          width="100%"
          height="44px"
          rounded
          :loading="loading"
          @click="handleApply"
        >
          Подтвердить
        </v-btn>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="grey"
          @click="closeDialog"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import getMathHeaders from '@/helpers/common/axiosHeaders';

export default {
  name: 'TwoFactorTabItemDialogApply',

  data() {
    return {
      code: '',
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      dialogs: 'settings/dialogs',
      twoFactor: 'settings/twoFactor',
    }),
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('settings/closeDialog', 'apply');
    },

    handleApply() {
      const data = {
        [this.code.length === 6 ? 'code' : 'recovery_code']: this.code,
      };

      // eslint-disable-next-line default-case
      switch (this.twoFactor.applyType) {
        case 'changeDevice':
          this.changeDevice(data);
          break;
        case 'turnOff':
          this.disable(data);
          break;
        case 'login':
          this.login(data);
          break;
      }
    },

    changeDevice(codeData) {
      this.loading = true;

      this.api.post('/two-factory/change-device', codeData)
        .then(async ({ data }) => {
          this.closeDialog();
          await this.$store.dispatch('settings/setTwoFactor', {
            qr: data['qr-code'],
            secretKey: data.secretKey,
          });
          this.$store.dispatch('settings/openDialog', 'changeDevice');
        }).finally(() => {
          this.loading = false;
        });
    },

    disable(codeData) {
      this.loading = true;

      this.api.post('/two-factory/disable', codeData)
        .then(() => {
          this.$store.dispatch('settings/updateProfileLocally', {
            twoFactorEnableDisable: false,
          });
          this.closeDialog();
        }).finally(() => {
          this.loading = false;
        });
    },

    async login(codeData) {
      this.loading = true;
      const mathHeaders = await getMathHeaders();
      this.api.post(
        '/auth/login-two-factory',
        {
          username: this.twoFactor.username,
          password: this.twoFactor.password,
          ...codeData,
        },
        {
          headers: { ...this.api.headers, ...mathHeaders },
        },
      )
        .then(({ data }) => {
          this.$emit('success', data);
          this.closeDialog();
        }).finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
