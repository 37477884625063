<template>
  <div>
    <v-tabs
      v-model="tab"
      grow
      dark
    >
      <v-tabs-slider />

      <v-tab>
        Двухфакторная аутентификация
      </v-tab>
    </v-tabs>

    <v-progress-linear
      v-if="loading.profile"
      indeterminate
    />

    <v-tabs-items v-model="tab">
      <v-tab-item :value="0">
        <two-factor-tab-item />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import TwoFactorTabItem from '@/components/settings/twoFactor/TwoFactorTabItem.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Settings',

  components: {
    TwoFactorTabItem,
  },

  data() {
    return {
      tab: 0,
    };
  },

  computed: {
    ...mapGetters({
      loading: 'settings/loading',
    }),
  },
};
</script>
