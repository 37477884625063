<template>
  <v-container class="two-factor-container">
    <template v-if="profile.id">
      <two-factor-tab-item-manage v-if="profile.twoFactorEnableDisable" />
      <two-factor-tab-item-enable v-else />
    </template>

    <two-factor-tab-item-dialog-codes v-if="dialogs.backupCodes" />
    <two-factor-tab-item-dialog-apply v-if="dialogs.apply" />
    <two-factor-tab-item-dialog-change-device v-if="dialogs.changeDevice" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import TwoFactorTabItemEnable from './TwoFactorTabItemEnable.vue';
import TwoFactorTabItemManage from './TwoFactorTabItemManage.vue';
import TwoFactorTabItemDialogCodes from './TwoFactorTabItemDialogCodes.vue';
import TwoFactorTabItemDialogApply from './TwoFactorTabItemDialogApply.vue';
import TwoFactorTabItemDialogChangeDevice from './TwoFactorTabItemDialogChangeDevice.vue';

export default {
  name: 'TwoFactorTabItem',

  components: {
    TwoFactorTabItemEnable,
    TwoFactorTabItemManage,
    TwoFactorTabItemDialogCodes,
    TwoFactorTabItemDialogApply,
    TwoFactorTabItemDialogChangeDevice,
  },

  computed: {
    ...mapGetters({
      profile: 'settings/profile',
      dialogs: 'settings/dialogs',
    }),
  },

  created() {
    if (!this.profile.id) {
      this.$store.dispatch('settings/loadProfile');
    }
  },
};
</script>

<style>
.two-factor-container {
  height: calc(100vh - 48px - 16px)
}
.two-factor-container .v-card,
.change-device-container .v-card {
  height: 100%;
}
.card-text-with-button {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-text-title {
  color: #FFFFFF;
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 12px;
}
.card-text-subtitle {
  font-size: 16px;
  font-weight: bold;
}
</style>
