<template>
  <v-dialog
    scrollable
    :value="dialogs.changeDevice"
    width="1100"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card class="change-device-container">
      <v-card-title>
        Сменить устройство
      </v-card-title>

      <v-card-text>
        <two-factor-tab-item-enable-content
          :loading="loading"
          :error="error"
          :handle-action="handleEnable"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import TwoFactorTabItemEnableContent from './TwoFactorTabItemEnableContent.vue';

export default {
  name: 'TwoFactorTabItemDialogChangeDevice',

  components: {
    TwoFactorTabItemEnableContent,
  },

  data() {
    return {
      qr: '',
      secretKey: '',
      loading: {
        qr: false,
        enable: false,
      },
      error: '',
    };
  },

  computed: {
    ...mapGetters({
      dialogs: 'settings/dialogs',
    }),
  },

  destroyed() {
    this.$store.dispatch('settings/setTwoFactor', {
      qr: '',
      secretKey: '',
    });
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('settings/closeDialog', 'changeDevice');
    },

    async handleEnable(code) {
      try {
        this.loading.enable = true;

        const { status, data } = await this.api.post('/two-factory/confirm-change-device', { code });

        if (status === 200) {
          this.$store.dispatch('main/alert', { color: 'success', message: 'Успешно' });
          this.closeDialog();
          await this.$store.dispatch('settings/setTwoFactor', {
            backupCodes: data,
          });
          this.$store.dispatch('settings/openDialog', 'backupCodes');
        }
      } finally {
        this.loading.enable = false;
      }
    },
  },
};
</script>
